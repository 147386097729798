import React, { useState, useEffect } from 'react';
import customFetch from "./apicall/api";
import { withRouter } from "react-router";
import { useLocation } from 'react-router-dom';
import SubmitButton from './SubmitButton';
import axios from 'axios';
import { ReactFormGenerator } from '@acen1/react-form-builder-nd';
import { insert_mongopayload, update_mongopayload } from './Services';
import { Select, Modal, Button, Input, DatePicker } from "antd";

const FormBuildView = (props) => {
  const { initialFormData = [], fetchDataUrl, embedded = false, payload_data = [], formConfig, payload_config, getPayloads, closeFormViewModal, setOnFormviewSubmit, setOnFormviewUpdateSubmit, handlePopupMessage } = props;
  const location = useLocation();
  const [formData, setFormData] = useState(initialFormData);
  const [formInputData, setFormInputData] = useState([]);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [paramsPayloadData, setParamsPayloaddata] = useState([]);
  const [paramsFormConfig, setParamsFormConfig] = useState([]);
  const [paramsPayloadConfig, setParamsPayloadConfig] = useState([]);
  const [apiResponseModal, setApiResponseModal] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [isspin, setIsspin] = useState(false);
  const [payloadDataForSW, setPayloadDataForSW] = useState([]);
  const [payloadConfigForSW, setPayloadConfigForSW] = useState();
  const [updatedFields, setUpdatedFields] = useState({});
  const [initValForPayloadpath, setInitValForPayloadpath] = useState({});
  const [finalValForPayloadpath, setFinalValForPayloadpath] = useState({});

  const state = window.history.state;

  useEffect(() => {
    if (state && state.formConfig && state.formConfig.length) {
      setFormData(JSON.parse(state.formConfig))
      setPayloadConfigForSW(JSON.parse(state.payload_config))
      state.payload_data.length && setPayloadDataForSW([JSON.parse(state.payload_data)])
    }
  }, [state])
  
  useEffect(() => {
    console.log("formConfig", formConfig)
    document.title = "FormBuildView";
    if (!window.sharedState && formConfig) {
      setFormData(formConfig);
      fetchDropdownData(formConfig, localStorage.getItem("api_token"));
    }
  }, []);
  
  useEffect(() => {
    const sharedState = window.sharedState;
    if (sharedState && sharedState.formConfig) {
      try {
        const formConfigData = JSON.parse(sharedState.formConfig);
        setFormData(formConfigData);
        fetchDropdownData(formConfigData, localStorage.getItem("api_token"));

        if (sharedState.payload_data && sharedState.payload_data.length) {
          setParamsPayloaddata([JSON.parse(sharedState.payload_data)]);
        }
        setParamsFormConfig(formConfigData);
        setParamsPayloadConfig(JSON.parse(sharedState.payload_config));

        if (sharedState.api_token) {
          localStorage.setItem('api_token', sharedState.api_token);
        }
      } catch (error) {
        console.error("Error parsing formConfig or payload_data:", error);
      }
    }
  }, []);
  // getDataFronresponse Function
  const getDataFronresponse = (data) => {
    const result = [];

    // Iterate over all keys in the data object
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        result.push({ key: key, value: data[key] });
      }
    }

    return result;
  };

  const transformApiData = (apiResponse, getoutputdata) => {

    const keyRegex = /{{(.*?)}}/g;
    const keys = [];
    let match;

    // Extract keys from the output data template
    while ((match = keyRegex.exec(getoutputdata)) !== null) {
      keys.push(match[1]);
    }


    // Transform the API response
    const transformedData = apiResponse.map((item) => {
      const option = {};

      keys.forEach((key, index) => {
        const keyParts = key.split('.');
        let value = item;

        for (let i = 0; i < keyParts.length; i++) {
          const part = keyParts[i];
          if (value && value[part] !== undefined) {
            value = value[part];
          } else {
            value = undefined;
            break;
          }
        }
        // Ensure the `value` is numeric if possible
        if (index === 1 && typeof value === "string" && !isNaN(value)) {
          value = Number(value);
        }

        if (index === 0) {
          option.text = value || ''; // Use the first key for the dropdown text
        } else if (index === 1) {
          option.value = value !== undefined ? value : ''; // Use the second key for the dropdown value
        }
      });

      return option;
    });

    return transformedData;
  };

  const fetchDropdownData = async (fields, token) => {
    for (const field of fields) {
        console.log("field", field);
        if (field.selectedApiUrl && field.selectedApiUrl.api_url) {
            try {
                // Check and process query parameters in the API URL
                const processedUrl = field.selectedApiUrl.api_url.replace(/{{(.*?)}}/g, (_, key) => {
                    const trimmedKey = key.trim();
                    const queryParam = this.getValue(field.payload, trimmedKey); // Assume `field.payload` holds the relevant data

                    // Handle special predefined keys
                    if (trimmedKey === "user_id") {
                        return localStorage.getItem("in_userid");
                    }
                    if (trimmedKey === "branch_id") {
                        return localStorage.getItem("branch_id");
                    }
                    if (
                        trimmedKey === "effective_designation_id" ||
                        trimmedKey === "effective_designation"
                    ) {
                        return localStorage.getItem("e_designation_id");
                    }

                    // Handle object-based query parameters
                    if (typeof queryParam === "object" && queryParam !== null) {
                        return Object.entries(queryParam)
                            .map(
                                ([key, value]) =>
                                    `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
                            )
                            .join("&");
                    } else {
                        // Handle scalar values
                        return encodeURIComponent(queryParam || "");
                    }
                });

                const url = process.env.REACT_APP_URL + field.selectedApiUrl.api_url;
                let response = await customFetch(url, {
                    method: "GET",
                    headers: { "api-token": token },
                });
                response = await response.json();

                const path = field.selectedApiUrl.output_path.split(".");
                const res_path = path ? path.reduce((acc, curr) => acc[curr], response) : response;

                if (res_path.length > 0) {
                    const transformedData = transformApiData(
                        res_path,
                        field.selectedApiUrl.getoutputdata
                    );

                    const options = transformedData.map((item, index) => ({
                        value: item.value,
                        text: item.text,
                        key: `dropdown_option_${index}_${Math.random().toString(36).substr(2, 9)}`,
                    }));

                    field.options = options;
                    setDropdownOptions((prev) => [...prev, ...options]);
                }
            } catch (error) {
                console.error("Error fetching dropdown data: ", error);
            }
        }
    }
    setFormData([...fields]);
};


  // const fetchDropdownData = async (fields, token) => {
  //   for (const field of fields) {
  //     if (field.selectedApiUrl && field.selectedApiUrl.api_url) {
  //       try {
  //         const url = process.env.REACT_APP_URL + field.selectedApiUrl.api_url;
  //         const response = await axios.get(url, { headers: { 'api-token': token } });
  //         if (response.data.length > 0) {
  //           // Transform the data
  //           const transformedData = getDataFronresponse(response.data[0]);
  //           // Generate dropdown options dynamically from API response
  //           const options = response.data[0].map((item, index) => ({
  //             value: item.value, // Set the value to the key from API response
  //             text: item.key, // Format the text to show key and value
  //             key: `dropdown_option_${index}_${Math.random().toString(36).substr(2, 9)}`
  //           }));
  //           // Update the specific dropdown field with generated options
  //           field.options = options;
  //           setDropdownOptions(prev => [...prev, ...options]);
  //         }
  //       } catch (error) {
  //         console.error("Error fetching dropdown data: ", error);
  //       }
  //     }
  //   }
  //   setFormData([...fields]);
  // };

  const handleInputChange = (data) => {
    if ((payload_data.length && payload_data[0]._id && payload_data[0]._id.$oid) || (paramsPayloadData[0] && paramsPayloadData[0]._id && paramsPayloadData[0]._id.$oid) || (payloadDataForSW[0] && payloadDataForSW[0]._id && payloadDataForSW[0]._id.$oid)) {

    const initialData = paramsPayloadData.length ? paramsPayloadData : payloadDataForSW.length ? payloadDataForSW : Array.isArray(payload_data) ? payload_data : [payload_data]
    const result = {};
    formData.forEach(item => {
      let value = initialData[0]; // Start with the first object in data1
      if(item.payloadpath) {
        const keys = item.payloadpath.split('.');
      
        // Traverse the keys to find the nested value
        keys.forEach(key => {
          value = value[key];
        });
      }
      // Store the value with payloadpath as the key
      result[item.field_name] = value;
      setInitValForPayloadpath(result)
    });
    let response = data.filter(item => 
      initValForPayloadpath.hasOwnProperty(item.name) && initValForPayloadpath[item.name] != item.value && (Array.isArray(initValForPayloadpath[item.name]) ? JSON.stringify(initValForPayloadpath[item.name]) !== JSON.stringify(item.value) : true)
  );
  response = response.filter(item=> item.value!==null || (typeof item.value === 'string' && item.value.length!==0))
  
  const finalUpdatedvaluewithPayloadpath = {};
  const res = response.map((item) => {
    finalUpdatedvaluewithPayloadpath[findPayloadPath(item.name)]= item.value
  })

  setFinalValForPayloadpath(finalUpdatedvaluewithPayloadpath);
    }
    if (JSON.stringify(data) !== JSON.stringify(formInputData)) {
      setFormInputData(Array.isArray(data) ? data : []);

      // Identify updated fields
      const changedFields = {};
      data.forEach((item) => {
        const original = formInputData.find((originalItem) => originalItem.id === item.id);
        if (!original || JSON.stringify(original.value) !== JSON.stringify(item.value)) {
          changedFields[item.name] = item.value;
        }
      });

      // Update the state for tracking changes
      setUpdatedFields((prevFields) => ({ ...prevFields, ...changedFields })); 
    }
  };

  const findPayloadPath = (name) => {
    const field = formData.find(item => item.field_name === name || item.id === name);
    return field ? field.payloadpath : undefined;
  };

  const transformDataStructure = (processedData) => {
    const result = {};
    processedData.forEach(item => {
      if (item.payload_path && item.value !== undefined) {
        const pathParts = item.payload_path.split('.');
        let currentLevel = result;

        for (let i = 0; i < pathParts.length; i++) {
          const part = pathParts[i];
          if (i === pathParts.length - 1) {
            currentLevel[part] = item.value;
          } else {
            if (!currentLevel[part]) currentLevel[part] = {};
            currentLevel = currentLevel[part];
          }
        }
      }
    });
    return result;
  };

  const handleSave = async () => {
    console.log('formData', formData);
    console.log('formInputData', formInputData);

    const results = [];
    formData.forEach(item1 => {
      const match = formInputData.find(item2 => item2.id === item1.id);
      if (match && item1.options) {
        const matchingOptions = item1.options.filter(option => match.value.includes(option.key));
        matchingOptions.forEach(option =>
          results.push({ payload_path: item1.payloadpath, value: option.value })
        );
      }
    });


    if (Array.isArray(formInputData)) {
      const processedData = formInputData.map((item) => {
        let value = item.value;
        const payloadPath = findPayloadPath(item.name);

        if (typeof value === "string") {
          if (item.name.startsWith("number_input") || item.name.startsWith("phone_input")) {
            value = value !== "" ? Number(value) : null;
          } else if (item.name.startsWith("date_picker")) {
            value = value ? new Date(value) : null;
          }
        }

        return {
          name: item.name,
          value: value,
          payload_path: payloadPath || "",
        };
      });

      const api_token = localStorage.getItem('api_token');

      if (!api_token) {
        console.error('API token is missing from localStorage.');
        return;
      }

      const uploadedFiles = [];

      formData.forEach((field) => {
        if (["FileUpload", "Camera", "Image", "Download"].includes(field.element)) {
          const matchingInput = formInputData.find((input) => input.id === field.id);
          if (matchingInput && matchingInput.value instanceof File) {
            uploadedFiles.push({
              files: [matchingInput.value],
              uploadPath: field.payload_path || '',
              formInputId: field.id,
            });
          }
        }
      });

      console.log("Uploaded files array:", uploadedFiles);

      if (uploadedFiles.length) {
        try {
          const formDataForUpload = new FormData();

          const remotePaths = formData
            .filter((item) => item.filePath && item.filePath.trim() !== "")
            .map((item) => item.filePath)
            .join(",");

          uploadedFiles.forEach((fileGroup) => {
            fileGroup.files.forEach((file) => {
              formDataForUpload.append("file", file, file.name);
            });
          });

          formDataForUpload.append("uploaded_by", localStorage.getItem("in_userid") || "unknown_user");
          formDataForUpload.append("document_type", "");
          formDataForUpload.append("remote_path", remotePaths || "");
          formDataForUpload.append("family_member_id", 0);

          const response = await customFetch(`${process.env.REACT_APP_URL}/multiple/file/upload`, {
            method: "POST",
            headers: {
              "api-token": api_token,
            },
            body: formDataForUpload,
          });

          if (!response.ok) {
            throw new Error(`Upload failed with status: ${response.status} ${response.statusText}`);
          }

          const uploadResponse = await response.json();
          if (!Array.isArray(uploadResponse)) {
            throw new Error("Upload response is not an array. Check API response structure.");
          }

          uploadedFiles.forEach((fileGroup, index) => {
            fileGroup.uploadedPath = uploadResponse[index];

            const matchingInput = formInputData.find((input) => input.id === fileGroup.formInputId);
            if (matchingInput) {
              matchingInput.value = {
                uploaded_files: [fileGroup.uploadedPath],
              };
              console.log(`Updated formInputData for field ${fileGroup.formInputId}:`, matchingInput);

              const matchingProcessedItem = processedData.find(
                (item) => item.name === matchingInput.name
              );
              if (matchingProcessedItem) {
                matchingProcessedItem.value = {
                  uploaded_files: [fileGroup.uploadedPath],
                };
                console.log(`Updated processedData for field ${matchingInput.name}:`, matchingProcessedItem);
                finalValForPayloadpath[findPayloadPath(matchingProcessedItem.name)] = matchingProcessedItem.value
              }

              if (fileGroup.uploadPath) {
                const pathSegments = fileGroup.uploadPath.split(".");
                let currentNode = processedData;

                pathSegments.forEach((segment, idx) => {
                  if (!currentNode[segment]) {
                    currentNode[segment] = {};
                  }
                  if (idx === pathSegments.length - 1) {
                    currentNode[segment]["uploaded_files_bank"] = [fileGroup.uploadedPath];
                  } else {
                    currentNode = currentNode[segment];
                  }
                });
              }
            }
          });

          processedData.forEach((data) => {
            if (data.name === "uploaded_files" && Array.isArray(data.value)) {
              data.value = data.value.flat();
            }
          });
        } catch (error) {
          console.error("Error during file upload:", error);
        }
      }

      const convertedData = transformDataStructure(processedData);
      console.log("Final Processed Data:", processedData);
      console.log("Converted Data:", convertedData);
      // Retrieve the User Login ID (from localStorage or another source)
      const in_userid = Number(localStorage.getItem("in_userid")); // Update this key based on where you store the User Login ID

      convertedData["add_data_before"] = {};

      if (payloadDataForSW.length) {
        if (payloadDataForSW[0]._id.$oid) {
          convertedData["updated_at"] = Date.now();
          convertedData["updated_by"] = in_userid; // Add updated_by field
        } else {
          convertedData["created_at"] = Date.now();
          convertedData["created_by"] = in_userid; // Add created_by field
        }
      } else if (paramsPayloadData.length) {
        if (paramsPayloadData[0]._id.$oid) {
          convertedData["updated_at"] = Date.now();
          convertedData["updated_by"] = in_userid; // Add updated_by field
        } else {
          convertedData["created_at"] = Date.now();
          convertedData["created_by"] = in_userid; // Add created_by field
        }
      } else {
        if (payload_data.length === 0) {
          if (
            (state &&
              state.payload_config &&
              JSON.parse(state.payload_config) &&
              JSON.parse(state.payload_config).setcreated_at === "1") ||
            (payload_config && payload_config.setcreated_at === "1") ||
            (window.sharedState.payload_config &&
              JSON.parse(window.sharedState.payload_config) &&
              JSON.parse(window.sharedState.payload_config).setcreated_at === "1")
          ) {
            convertedData["created_at"] = Date.now();
            convertedData["created_by"] = in_userid; // Add created_by field
          }
        } else {
          if (payload_config.setupdated_at === "1") {
            convertedData["updated_at"] = Date.now();
            convertedData["updated_by"] = in_userid; // Add updated_by field
          }
        }
      }

      // Convert status to a number if it exists
      if (convertedData["status"]) {
        convertedData["status"] = Number(convertedData["status"]);
      }
      
      console.log('payload_config', payload_config)
      convertedData["branch_id"] = Number(convertedData["branch_id"])
      const db_name = paramsPayloadConfig && paramsPayloadConfig.db_name ? paramsPayloadConfig.db_name : payloadConfigForSW && payloadConfigForSW.db_name ? payloadConfigForSW.db_name : payload_config.db_name
      const collection_name = paramsPayloadConfig && paramsPayloadConfig.collection_name ? paramsPayloadConfig.collection_name : payloadConfigForSW && payloadConfigForSW.collection_name ? payloadConfigForSW.collection_name : payload_config.collection_name
      const objId = paramsPayloadData[0] && paramsPayloadData[0]._id.$oid ? paramsPayloadData[0]._id.$oid : payloadDataForSW[0] && payloadDataForSW[0]._id.$oid ? payloadDataForSW[0]._id.$oid : payload_data.length && payload_data[0]._id.$oid ? payload_data[0]._id.$oid : ""
      if ((payload_data.length && payload_data[0]._id && payload_data[0]._id.$oid) || (paramsPayloadData[0] && paramsPayloadData[0]._id && paramsPayloadData[0]._id.$oid) || (payloadDataForSW[0] && payloadDataForSW[0]._id && payloadDataForSW[0]._id.$oid)) {
        setIsspin(true)
        // Remove the 'household_block_data' key
        delete convertedData.household_block_data;
        delete finalValForPayloadpath.undefined
        let responseFromUpdateMongoPayload = await update_mongopayload(
          localStorage.getItem("api_token"),
          db_name,
          collection_name,
          objId,
          {
            // ...convertedData,
            ...finalValForPayloadpath
          },
        );
        if (responseFromUpdateMongoPayload.status == 1) {
          localStorage.setItem('apiSuccess', Date.now());
        }

        if (responseFromUpdateMongoPayload.message) {
          setPopupMessage(responseFromUpdateMongoPayload.message)
          setApiResponseModal(true)
        }
        // if (handlePopupMessage) {
        //   handlePopupMessage("abc");
        // }
        if (closeFormViewModal && typeof closeFormViewModal === 'function') {
          closeFormViewModal(); // Call it when necessary
        }
        // console.log('responseFromUpdateMongoPayload', responseFromUpdateMongoPayload)
        // if (setOnFormviewSubmit && typeof setOnFormviewSubmit === 'function') {
        //   setOnFormviewSubmit(true)
        // }
        console.log("newForm:", setOnFormviewUpdateSubmit);
        // if (setOnFormviewUpdateSubmit && typeof setOnFormviewUpdateSubmit === 'function') {
        // console.log("set true:", setOnFormviewUpdateSubmit);
        //   setOnFormviewUpdateSubmit(true)
        // }
        if (getPayloads && typeof getPayloads === 'function') {
          getPayloads(); // Call it when necessary
        }
        setIsspin(false)
        return;
      }
      setIsspin(true)
      try {
        // Insert data into MongoDB
        console.log("Starting MongoDB insertion...");
        let responseFromInsertMongoPayload = await insert_mongopayload(
          localStorage.getItem('api_token'),
          db_name,
          collection_name,
          convertedData
        );
        console.log("MongoDB insertion response:", responseFromInsertMongoPayload);

        // Stop the spinner
        setIsspin(false);

        // Close the form modal if the function is provided
        if (closeFormViewModal && typeof closeFormViewModal === 'function') {
          closeFormViewModal();
        }

        if (responseFromInsertMongoPayload.message) {
          setPopupMessage(responseFromInsertMongoPayload.message)
          setApiResponseModal(true)
        }

        // Check if setOnFormviewSubmit is a function and then call it
        console.log("newForm:", setOnFormviewSubmit);
        if (setOnFormviewSubmit) {
          setOnFormviewSubmit(true);
        } else {
          console.error("setOnFormviewSubmit is undefined or invalid.");
        }

        console.log("Processed form input values:", processedData);
        console.log("Final converted data object:", convertedData);
      } catch (error) {
        // Handle errors and stop spinner
        setIsspin(false);
      }
    } else {
      console.log('No form input data available or form is empty.');
    }
  };

  // Converts processed data into an object with payload paths as keys
  const convertProcessedDataToObject = (processedData) => {
    console.log("Input processedData:", processedData);

    const result = {};

    processedData.forEach(item => {
      if (item.payload_path && item.value !== undefined) {
        result[item.payload_path] = item.value; // value includes the processed checkbox data
        console.log(`Mapping "${item.payload_path}" to "${item.value}"`);
      }
    });

    return result;
  };

  if (!formData || formData.length === 0) return <div>Loading...</div>;

  return (
    <div className="container mt-5">
      {isspin && (
        <div className="preloader">
          <div className="loader" ng-hide="data.length > 0"></div>
        </div>
      )}
      <div className="row mb-4">
        <div className="col">
          <ReactFormGenerator
            download_path=""
            back_action="/"
            answer_data={{}}
            form_action="/api/form"
            form_method="POST"
            data={formData}
            locale="en"
            onChange={handleInputChange}
            payload_data={paramsPayloadData.length ? paramsPayloadData : payloadDataForSW.length ? payloadDataForSW : Array.isArray(payload_data) ? payload_data : [payload_data]}
            payload_path={formData.map(field => field.payloadpath).flat()}
            dropdownOptions={dropdownOptions}
          />
          <SubmitButton
            onSave={handleSave}
            text="Submit Form"
          />
        </div>
      </div>
      <Modal
        open={apiResponseModal}
        onCancel={() => {
          setApiResponseModal(false)
          window.close()
        }}
        width="auto"
        footer={null}
      >
        <p>{popupMessage}</p>
      </Modal>
    </div>
  );
};

export default withRouter(FormBuildView);
