import React, { useState, useEffect } from "react";
import moment from "moment";
import { PESelect } from "./permissionComponents";
import customFetch from "./apicall/api";
import Cash_balance_report from "./Cash_balance_report";
import { ReloadOutlined } from "@ant-design/icons";
import { DatePicker } from "antd";

const { RangePicker } = DatePicker;

const Form = ({ apiToken, reportListData }) => {
  const [reportConfig, setReportConfig] = useState(null);
  const [currentReportId, setCurrentReportId] = useState(null);
  const [formState, setFormState] = useState({});
  const [isFetchingConfig, setIsFetchingConfig] = useState(false);
  const [isErrorWindowActive, setErrorWindowActive] = useState(false);
  const [errorWindowMessage, setErrorWindowMessage] = useState("");
  const [processedAtCount, setProcessedAtCount] = useState(0);
  const [isFetchingReportLog, setFetchingReportLog] = useState(false);
  const [reportData, setReportData] = useState([]);

  useEffect(() => {
    if (currentReportId) getReportData()
  }, [currentReportId])

  const initializeFormState = (config) => {
    const initialState = {};

    const isEmptyCondition = (condition) => {
      return !condition || (Array.isArray(condition) && condition.length === 0);
    };

    config && Object.keys(config).forEach((key) => {
      const configItem = config[key]
      let visibleState = false
      if (configItem.source === "API") {
        visibleState = isEmptyCondition(configItem.visibility_conditions) &&
          isEmptyCondition(configItem.query_dependencies);
      } else {
        visibleState = isEmptyCondition(configItem.visibility_conditions);
      }
      initialState[key] = {
        id: "",
        value: "",
        isVisible: visibleState,
        isFetching: false,
      };
    })
    setFormState(initialState);
  };

  const fetchReportConfig = async (reportId) => {
    try {
      const response = await customFetch(
        `${process.env.REACT_APP_URL}/get/parameters?report_id=${reportId}`,
        {
          method: "GET",
          headers: { "api-token": apiToken },
        }
      );

      if (!response.ok) throw new Error("Failed to fetch report config");

      const data = await response.json();

      if (data && data.length > 0) {
        data[0].report_config = JSON.parse(data[0].report_config);

        const processedConfig = await Promise.all(
          Object.keys(data[0].report_config).map(async (key) => {
            const configItem = data[0].report_config[key];
            if (initialFetchState(configItem)) {
              const processedItem = await processAPIConfigItem(key, configItem, data[0].report_id);
              return [key, processedItem];
            }
            return [key, configItem];
          })
        );

        const structuredConfig = Object.fromEntries(processedConfig);

        // Separate fields with and without 'order' field
        const fieldsWithOrder = Object.keys(structuredConfig)
          .filter(key => structuredConfig[key].order !== undefined) // fields with 'order'
          .map(key => ({ key, ...structuredConfig[key] }));

        const fieldsWithoutOrder = Object.keys(structuredConfig)
          .filter(key => structuredConfig[key].order === undefined) // fields without 'order'
          .map(key => ({ key, ...structuredConfig[key] }));

        // Sort the fields with 'order' by the 'order' field
        const sortedFieldsWithOrder = fieldsWithOrder.sort((a, b) => (a.order || 0) - (b.order || 0));

        // Combine fields with 'order' and fields without 'order' (fields without order remain in original order)
        const finalSortedConfig = [...sortedFieldsWithOrder, ...fieldsWithoutOrder];

        // Convert the sorted array back to an object
        const finalSortedConfigObj = Object.fromEntries(finalSortedConfig.map(({ key, ...rest }) => [key, rest]));

        return finalSortedConfigObj;
      }

      return null;
    } catch (error) {
      setErrorWindowActive(true)
      setErrorWindowMessage("Error fetching report config:" + error)
      console.error("Error fetching report config:", error);
      return null;
    }
  };

  const initialFetchState = (configItem) => {
    if (configItem.type === "textbox" || configItem.source !== "API") return false
    if (configItem.source === "API") {
      // return (!configItem.visibility_conditions || (Array.isArray(configItem.visibility_conditions && configItem.visibility_conditions.length === 0))) &&
      //   (!configItem.query_dependencies || (Array.isArray(configItem.query_dependencies && configItem.query_dependencies.length === 0)))
      return !configItem.query_dependencies || (Array.isArray(configItem.query_dependencies) && configItem.query_dependencies.length === 0)
    }
    return true
  }

  const processAPIConfigItem = async (configKey, configItem, reportId, dependentValues) => {
    try {
      const body = {
        keyname: configKey,
        params: dependentValues || [],
      };

      const response = await customFetch(
        `${process.env.REACT_APP_URL}/reports/${reportId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "api-token": apiToken,
          },
          body: JSON.stringify(body),
        }
      );

      if (!response.ok) throw new Error("Failed to fetch API config data");

      const result = await response.json();
      const apiData = result.data || [];

      let updatedItem = { ...configItem };

      // Generic handling for dropdowns and other field types
      switch (configItem.type) {
        case "Dropdown":
          updatedItem = {
            ...configItem,
            options: apiData.map((item) => ({
              label: item.id,
              value: item.datakey,
            })),
          };
          break;

        case "textbox":
          updatedItem = {
            ...configItem,
            status: result.status,
          };
          if (result.status === 1) {
          }
          break;

        default:
          updatedItem = {
            ...configItem,
            ...apiData[0],
          };
          break;
      }

      // After the switch block, update the form state
      // setFormState((prevState) => ({
      //   ...prevState,
      //   [configKey]: { ...prevState[configKey], isFetching: false },
      // }));

      return updatedItem;

    } catch (error) {
      setErrorWindowMessage(`Error fetching data: ${error.message}`);
      setErrorWindowActive(true);

      // setFormState((prevState) => ({
      //   ...prevState,
      //   [configKey]: { ...prevState[configKey], isFetching: false },
      // }));

      throw error; // Re-throw to propagate error handling
    }
  };

  const handleReportChange = async (selectedValue) => {
    if (selectedValue) {
      setIsFetchingConfig(true);
      const config = await fetchReportConfig(selectedValue);
      if (config) {
        setReportConfig(config);
        initializeFormState(config);
      }
      else {
        setReportConfig(null)
        initializeFormState()
      }
      setIsFetchingConfig(false);
      setCurrentReportId(selectedValue);
    }
  };

  const handleInputChange = async (key, newValue, forceSelfAPICall = false) => {
    const updatedFormState = { ...formState };
    const updatedReportConfig = { ...reportConfig };

    const resetDependentFields = (parentKey) => {
      Object.keys(updatedReportConfig).forEach(function (fieldKey) {
        const fieldConfig = updatedReportConfig[fieldKey];

        const hasVisibilityCondition = fieldConfig.visibility_conditions &&
          fieldConfig.visibility_conditions.some(function (condition) {
            return condition.parent_key === parentKey;
          });

        const hasQueryDependency = fieldConfig.query_dependencies &&
          fieldConfig.query_dependencies.includes(parentKey);

        if (hasVisibilityCondition || hasQueryDependency) {
          updatedFormState[fieldKey].value = null;
          updatedFormState[fieldKey].id = null;
          updatedFormState[fieldKey].isVisible = false;

          resetDependentFields(fieldKey);
        }
      });
    };

    // Function to update visibility recursively
    const updateVisibility = (fieldKey, visitedFields) => {
      if (visitedFields.includes(fieldKey)) {
        console.warn(`Circular dependency detected involving: ${fieldKey}`);
        return;
      }

      visitedFields.push(fieldKey);

      const fieldConfig = updatedReportConfig[fieldKey];
      if (!fieldConfig || !fieldConfig.visibility_conditions) return;

      // Check visibility conditions
      const isVisible = fieldConfig.visibility_conditions.every(function (condition) {
        const parentValue = updatedFormState[condition.parent_key]
          ? updatedFormState[condition.parent_key].id
          : null;

        return (
          parentValue !== null &&
          parentValue !== "" &&
          (!condition.allowed || condition.allowed.indexOf(parentValue) >= 0)
        );
      });

      // Add query_dependencies check
      const dependenciesSatisfied = fieldConfig.query_dependencies
        ? fieldConfig.query_dependencies.every((depKey) => {
          const depValue = updatedFormState[depKey] ? updatedFormState[depKey].value : null;
          return depValue !== null && depValue !== "";
        })
        : true;

      const finalVisibility = isVisible && dependenciesSatisfied;

      // Update visibility in the form state
      if (updatedFormState[fieldKey].isVisible !== finalVisibility) {
        updatedFormState[fieldKey].isVisible = finalVisibility;

        // If visibility changes to false, clear the field's value
        if (!finalVisibility) {
          updatedFormState[fieldKey].value = null;
          updatedFormState[fieldKey].id = null;
          resetDependentFields(fieldKey);
        }

        // If visibility changes, process dependent fields
        if (finalVisibility) {
          Object.keys(updatedReportConfig).forEach(function (dependentKey) {
            if (
              updatedReportConfig[dependentKey].visibility_conditions &&
              updatedReportConfig[dependentKey].visibility_conditions.some(function (cond) {
                return cond.parent_key === fieldKey;
              })
            ) {
              updateVisibility(dependentKey, visitedFields.slice()); // Pass a copy of the visited list
            }
          });
        }
      }
    };


    // Update the value of the current field, also adding the id
    const fieldConfig = updatedReportConfig[key];
    const foundOption = fieldConfig.options && fieldConfig.options.find(function (option) {
      return option.value === newValue;
    });
    const id = foundOption ? foundOption.label : newValue;
    updatedFormState[key] = {
      ...updatedFormState[key],
      value: newValue,
      id: id || newValue,
    };

    resetDependentFields(key)

    // Update visibility recursively
    Object.keys(updatedReportConfig).forEach(function (fieldKey) {
      const hasParentDependency = updatedReportConfig[fieldKey].visibility_conditions &&
        updatedReportConfig[fieldKey].visibility_conditions.some(function (cond) {
          return cond.parent_key === key;
        });

      const hasQueryDependency = updatedReportConfig[fieldKey].query_dependencies &&
        updatedReportConfig[fieldKey].query_dependencies.includes(key);

      if (hasParentDependency || hasQueryDependency) {
        updateVisibility(fieldKey, []);
      }
    });

    // Update form state
    setFormState(updatedFormState);

    // Handle API calls only for fields that meet the conditions
    const fieldsToFetch = Object.keys(updatedReportConfig).filter(function (fieldKey) {
      const fieldConfig = updatedReportConfig[fieldKey];

      // Check if the field has source: API
      const isSourceAPI = fieldConfig.source === "API";

      // Check if query_dependencies exist and are satisfied
      const dependenciesSatisfied = fieldConfig.query_dependencies
        ? fieldConfig.query_dependencies.every((depKey) => {
          return updatedFormState[depKey] && updatedFormState[depKey].value;
        })
        : true;

      // Check if the field is visible
      const isVisible = updatedFormState[fieldKey] && updatedFormState[fieldKey].isVisible;

      // Check if the field state has changed compared to the previous formState
      const hasChanged =
        (!fieldConfig.query_dependencies &&
          JSON.stringify(formState[fieldKey]) !== JSON.stringify(updatedFormState[fieldKey]))
        ||
        (fieldConfig.query_dependencies &&
          fieldConfig.query_dependencies.some(function (depKey) {
            return (
              formState[depKey] &&
              updatedFormState[depKey] &&
              JSON.stringify(formState[depKey].value) !== JSON.stringify(updatedFormState[depKey].value)
            );
          }));

      if (forceSelfAPICall && fieldKey === key) return isSourceAPI && dependenciesSatisfied && isVisible
      return isSourceAPI && dependenciesSatisfied && isVisible && hasChanged && fieldKey !== key;
    });

    // Make API calls for the fields that need it
    const fetchPromises = fieldsToFetch.map(async (fieldKey) => {
      const fieldConfig = updatedReportConfig[fieldKey];
      const dependentValues = fieldConfig.query_dependencies
        ? fieldConfig.query_dependencies.map(function (depKey) {
          return updatedFormState[depKey].id;
        })
        : [];

      if (fieldKey === key && fieldConfig.type === "textbox") dependentValues.push(updatedFormState[key].value)

      // Optionally show a loading indicator for the field
      setFormState((prevState) => ({
        ...prevState,
        [fieldKey]: { ...prevState[fieldKey], isFetching: true },
      }));

      try {
        // Fetch updated configuration for the field
        const processedItem = await processAPIConfigItem(
          fieldKey,
          fieldConfig,
          currentReportId,
          dependentValues
        );

        // Update the reportConfig with new configuration
        updatedReportConfig[fieldKey] = {
          ...updatedReportConfig[fieldKey],
          ...processedItem,
        };

        // Update the global state for reportConfig
        setReportConfig(updatedReportConfig);
      } catch (error) {
        // Handle errors (e.g., showing an error message)
        setErrorWindowActive(true);
        setErrorWindowMessage(`Error updating field: ${error.message}`);
      } finally {
        setFormState((prevState) => ({
          ...prevState,
          [fieldKey]: { ...prevState[fieldKey], isFetching: false },
        }));
      }
    });

    // Execute all the fetch requests in parallel
    await Promise.all(fetchPromises);
  };

  const handleTextboxChange = (key, newValue) => {
    const updatedFormState = { ...formState };
    updatedFormState[key] = { ...updatedFormState[key], value: newValue, id: newValue };
    setFormState(updatedFormState);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();

    const uid = localStorage.getItem("in_userid");
    formData.append("userid", uid);
    formData.append("report_id", currentReportId);

    const fieldsToSubmit = [];
    if (isFormStateNonEmpty()) {
      for (const key in formState) {
        if (formState.hasOwnProperty(key)) {
          const fieldState = formState[key];

          let is_req = true;
          if (reportConfig[key] && reportConfig[key].is_req !== null && reportConfig[key].is_req !== undefined) {
            is_req = reportConfig[key].is_req
            is_req = (is_req === "1" || is_req === true || is_req === "true" || is_req === 1);
          }

          // Check if value is present and not empty
          if (fieldState.isVisible && !fieldState.isFetching && fieldState.value && is_req) {
            // Handle special cases for calendar_daterange and calendar_yearmonthrange
            if (Array.isArray(fieldState.value)) {
              fieldState.value.forEach((item) => fieldsToSubmit.push({ id: item, value: item }))
            } else {
              const fieldData = {
                id: fieldState.id,
                value: fieldState.value,
              };

              fieldsToSubmit.push(fieldData);
            }
          }
        }
      }
      formData.append("user_parameter", JSON.stringify(fieldsToSubmit))
    }
    else {
      let nadata = [{}];
      nadata["nakey"] = "NA";
      formData.append("user_parameter", JSON.stringify(nadata, null));
    }

    setIsFetchingConfig(true)

    customFetch(`${process.env.REACT_APP_URL}/store/desktop/report/request/detail`, {
      method: 'POST',
      headers: {
        'api-token': apiToken,
      },
      body: formData,
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (
          data.api_code === 4 ||
          data.api_code === 3 ||
          data.api_code === 5
        ) {
          localStorage.clear();
          this.props.history.push("/");
        }
        getReportData()
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setIsFetchingConfig(false))
  };

  const isFormStateNonEmpty = (state) => {
    for (let key in state) {
      const value = state[key];

      if (value !== null && typeof value !== 'object') {
        if (value !== '' && value !== undefined) {
          return false;
        }
      }
      else if (Array.isArray(value)) {
        if (value.length > 0) {
          return false;
        }
      }
      else if (typeof value === 'object') {
        for (let field in value) {
          const fieldValue = value[field];
          if (fieldValue !== null && fieldValue !== undefined && fieldValue !== '') {
            return false;
          }
        }
      }
    }
    return true;
  };

  // Helper to check if all visible fields are completed with textbox `status` check
  const areVisibleFieldsComplete = () => {
    return formState && Object.keys(formState).every((key) => {
      const field = formState[key];
      const config = reportConfig[key];
      if (!field || !field.isVisible) return true; // Skip non-visible fields
      if (config.type === "textbox") return field.value && config.status === 1; // Check textbox status
      return field.value !== "" && field.value !== null; // Check other field types
    });
  };

  // Helper to determine if any field is fetching data
  const isAnyFieldFetching = () => {
    return Object.keys(formState).some((key) => formState[key] && formState[key].isFetching);
  };

  const checkFetchingState = () => {
    return isFetchingConfig || isFetchingReportLog || isAnyFieldFetching();
  };

  const downloadReportUpdateDate = async (request_no, download_link = "") => {
    const reqId = request_no.split("/").at(-1)
    try {
      const response = await customFetch(
        process.env.REACT_APP_URL + "/fetch/report/datetime?request_id=" + reqId,
        {
          method: "GET",
          headers: {
            "api-token": apiToken,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      window.location.href = download_link
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const getHours = (create_at) => {
    const dateOneObj = new Date(create_at);
    const dateTwoObj = new Date();
    const milliseconds = Math.abs(dateTwoObj - dateOneObj);
    const hours = milliseconds / 36e5;
    return hours;
  }

  const getInputData = (inputdata) => {
    let x = inputdata;
    let result = "";

    // Case 1: When inputdata is an array
    if (Array.isArray(x)) {
      x.forEach((item) => {
        if (item.hasOwnProperty("value")) {
          let date = moment(item.value);
          if (date.isValid()) {
            let dateAdded = false;
            reportConfig && Object.keys(reportConfig).forEach((inputkey) => {
              const config = reportConfig[inputkey];
              if (
                config.type === "calendar_daterange" ||
                config.type === "calendar_date"
              ) {
                if (!dateAdded) {
                  result += moment(item.value).format("YYYY-MM-DD") + " | ";
                  dateAdded = true;
                }
              } else if (
                config.type === "calendar_yearmonth" ||
                config.type === "calendar_yearmonthrange"
              ) {
                if (!dateAdded) {
                  result += moment(item.value).format("MMM-YYYY") + " | ";
                  dateAdded = true;
                }
              }
            });
            if (!dateAdded) {
              result += item.value + " | ";
            }
          } else {
            result += item.value + " | ";
          }
        }
      });

      return result
        .trim()
        .replace(/\|([^|]*)$/, "$1")
        .trim();
    }
    // Case 2: When inputdata is an object
    else {
      const arr = Object.keys(x).map((key) => ({ [key]: x[key] }));
      return arr.map((arrdata) => {
        if (arrdata.month) {
          return arrdata.month;
        } else if (arrdata.lender_name) {
          return arrdata.lender_name;
        }
      });
    }
  };


  const getReportData = () => {
    setFetchingReportLog(true);
    const uid = localStorage.getItem("in_userid");
    const formdata = new FormData();
    formdata.append("userid", uid);
    formdata.append("report_id", currentReportId);
    customFetch(process.env.REACT_APP_URL + "/get/request/queue", {
      method: "POST",
      headers: { "api-token": apiToken },
      body: formdata,
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        const processed_AtCount = data.reduce((count, item) => {
          return item.processed_at === null ? count + 1 : count;
        }, 0);
        setProcessedAtCount(processed_AtCount)
        setReportData(data)
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setFetchingReportLog(false)
      })
  }

  const getDisabledDateRange = (current, field, addType = "days") => {
    current = moment(current);

    const parseNumber = (value) => {
      const num = Number(value);
      return !isNaN(num) ? num : null;
    };

    const toMaxDate = field.to_max_date !== undefined && field.to_max_date !== null
      ? (parseNumber(field.to_max_date) !== null
        ? moment().add(parseNumber(field.to_max_date), addType)
        : moment(field.to_max_date))
      : null;

    const fromMaxDate = field.from_max_date !== undefined && field.from_max_date !== null
      ? (parseNumber(field.from_max_date) !== null
        ? moment().subtract(parseNumber(field.from_max_date), addType)
        : moment(field.from_max_date))
      : null;

    const defaultStartDate = field.default_start_dt
      ? moment(field.default_start_dt)
      : null;

    return (
      current &&
      (
        (defaultStartDate && current.isBefore(defaultStartDate)) ||
        (toMaxDate && current.isAfter(toMaxDate)) ||
        (fromMaxDate && current.isBefore(fromMaxDate))
      )
    );
  };

  return (
    <>
      {(checkFetchingState()) && (
        <div className="preloader">
          <div className="loader" ng-hide="data.length > 0"></div>
        </div>
      )}
      <div
        className={`modal fade ${isErrorWindowActive ? "show mymodal" : ""}`}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="errorModalLabel"
        aria-hidden={!isErrorWindowActive}
        style={{ display: isErrorWindowActive ? "block" : "none" }}
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content cdb-confirm-box col-sm-12">
            <button
              type="button"
              className="close text-right"
              aria-label="Close"
              onClick={() => {
                setErrorWindowActive(false);
                setErrorWindowMessage(""); // Clear error message on close
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <p>{errorWindowMessage}</p>
          </div>
        </div>
      </div>
      <div className="mt-12">
        <div className="row">
          {/* <form onSubmit={handleSubmit} className="dynamic-form"> */}
          {/* Fixed Dropdown */}
          <div className="col-md-4 form-group">
            <span htmlFor="reportSelector" className="f18">Select Report</span>
            <PESelect
              element_id={"SelectReport"}
              showSearch
              onChange={(e) => handleReportChange(e)}
              placeholder="-"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option.label || "").toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA.label || "").localeCompare(optionB.label || "")
              }
              options={
                reportListData &&
                reportListData.map(function (item) {
                  return {
                    label: item.report_name,
                    value: item.report_id,
                  };
                })
              }
            />
          </div>

          {currentReportId == 4 && (
            <Cash_balance_report api_token={apiToken} />
          )}

          {/* Render Dynamic Fields */}
          {reportConfig &&
            Object.keys(reportConfig).map(function (key) {
              const field = reportConfig[key];
              if (!formState[key] || !formState[key].isVisible) return null;

              switch (field.type) {
                case "Dropdown":
                  return (
                    <div key={key} className="col-md-4 form-group">
                      <span>{field.label || key}</span>
                      <select
                        id={key}
                        className="form-control text-center"
                        value={formState[key].value || ""}
                        onChange={(e) => handleInputChange(key, e.target.value)}
                        name={key}
                      >
                        <option value="" disabled> - </option>
                        {field.options && field.options.length > 0 && field.options.map((option) => {
                          return (
                            <option key={option.value} value={option.value}>
                              {option.value}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  );

                case "calendar_date":
                  return (
                    <div key={key} className="col-md-4">
                      <span>{field.label}</span>
                      <DatePicker
                        format={"YYYY-MM-DD"}
                        pickerClass="antd_dateform-control"
                        placeholder="Select Date"
                        onChange={(e) => {
                          let selectedDate = null;
                          if (e) {
                            selectedDate = moment(e).format("YYYY-MM-DD");
                          }
                          handleInputChange(key, selectedDate);
                        }}
                        disabledDate={(current) => getDisabledDateRange(current, field)}
                      />
                    </div>
                  );

                case "calendar_daterange":
                  return (
                    <div key={key} className="col-md-4">
                      <span>{field.label}</span>
                      <RangePicker
                        value={(formState[key] && formState[key].value) ? [
                          moment(formState[key].value[0]),
                          moment(formState[key].value[1])
                        ] : null}
                        format={"YYYY-MM-DD"}
                        pickerClass="antd_dateform-control"
                        placeholder={["Start Date", "End Date"]}
                        onChange={(e) => {
                          if (e && e.length === 2) {
                            const startDate = moment(e[0]);
                            const endDate = moment(e[1]);

                            // Check the gap condition
                            if (field.gap && endDate.diff(startDate, "days") > field.gap) {
                              // Trigger error but don't set the data
                              setErrorWindowActive(true);
                              setErrorWindowMessage(
                                field.error_message
                                  ? field.error_message
                                  : `Please select within the range of ${field.gap} days`
                              );
                              handleInputChange(key, null);
                              return; // Prevent data from being set
                            }

                            // Set the data if the gap condition is satisfied
                            handleInputChange(key, [
                              startDate.format("YYYY-MM-DD"),
                              endDate.format("YYYY-MM-DD"),
                            ]);
                          } else {
                            // Handle null case
                            handleInputChange(key, null);
                          }
                        }}
                        disabledDate={(current) => getDisabledDateRange(current, field)}
                      />
                    </div>
                  );

                case "calendar_yearmonth":
                  return (
                    <div key={key} className="col-md-4">
                      <span>{field.label}</span>
                      <DatePicker
                        format={"MMM-YY"}
                        pickerClass="antd_dateform-control"
                        picker="month"
                        placeholder="Select Month"
                        onChange={(e) => {
                          let selectedDate = null;
                          if (e) {
                            const monthDate = moment(e).format("YYYY-MM");
                            selectedDate = `${monthDate}-01`; // Set to the first day of the selected month
                          }
                          handleInputChange(key, selectedDate);
                        }}
                        disabledDate={(current) => getDisabledDateRange(current, field, "months")}
                      />
                    </div>
                  );

                case "calendar_yearmonthrange":
                  return (
                    <div key={key} className="col-md-4">
                      <span>{field.label}</span>
                      <RangePicker
                        value={(formState[key] && formState[key].value) ? [
                          moment(formState[key].value[0]),
                          moment(formState[key].value[1])
                        ] : null}
                        format={"MMM-YY"}
                        pickerClass="antd_dateform-control"
                        picker="month"
                        placeholder={["Start Month", "End Month"]}
                        onChange={(e) => {
                          if (e && e.length === 2) {
                            const startDate = moment(e[0]).startOf("month");
                            const endDate = moment(e[1]).endOf("month");

                            // Check the gap condition
                            if (field.gap && endDate.diff(startDate, "months") > field.gap) {
                              // Trigger error but don't set the data
                              setErrorWindowActive(true);
                              setErrorWindowMessage(
                                field.error_message
                                  ? field.error_message
                                  : `Please select within the range of ${field.gap} months`
                              );
                              handleInputChange(key, null)
                              return; // Prevent data from being set
                            }

                            // Set the data if the gap condition is satisfied
                            handleInputChange(key, [
                              startDate.format("YYYY-MM-DD"),
                              endDate.format("YYYY-MM-DD"),
                            ]);
                          } else {
                            // Handle null case
                            handleInputChange(key, null);
                          }
                        }}
                        disabledDate={(current) => getDisabledDateRange(current, field, "months")}
                      />
                    </div>
                  );

                case "textbox":
                  return (
                    <div key={key} className="col-md-4">
                      <span>{field.label}</span>
                      <input
                        className="form-control"
                        name={key}
                        value={formState[key].value}
                        onChange={e => handleTextboxChange(key, e.target.value)}
                        onBlur={e => handleInputChange(key, e.target.value, true)}
                      />
                      {field.status === 0 ? (
                        <span className="errmsg">
                          {field.error_message}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  )

                default:
                  return null;
              }
            })}

          {currentReportId && (areVisibleFieldsComplete() && !checkFetchingState()) && (
            <div className="col-md-2">
              <span className="f18">&nbsp;</span>
              <button
                disabled={processedAtCount > 0}
                className={
                  processedAtCount > 0
                    ? "form-control btn-primary p-2 btn-height disablesendreqbtn"
                    : "form-control btn-primary p-2 btn-height"
                }
                onClick={handleSubmit}
              >
                Send Request
              </button>
            </div>
          )}
        </div>
        {reportData.length > 0 && (
          <div className="mt-4">
            <div className="float-right">
              <button
                className="btn btn-primary mb-1"
                onClick={() => getReportData()}
              >
                <ReloadOutlined />
              </button>
            </div>
            <table className="table text-center">
              <thead className="table-head">
                <tr>
                  <th>Request No.</th>
                  <th>Parameters Selected</th>
                  <th>Submitted At</th>
                  <th>Completed At</th>
                  <th>Download Link</th>
                </tr>
              </thead>
              <tbody>
                {reportData.map((report) => (
                  <tr key={report.Request_No}>
                    <td>{report.Request_No}</td>
                    <td>{getInputData(report.Parameter_Selected)}</td>
                    <td>
                      {moment(report.Submitted_At).format("DD-MMM-YY HH:mm")}
                    </td>
                    <td>
                      {report.completed_at &&
                        moment(report.completed_at).format("DD-MMM-YY HH:mm")}
                    </td>
                    <td>
                      {report.Download_Link ? (
                        getHours(report.processed_at) <= 24 ? (
                          <a
                            // href={report.Download_Link}
                            onClick={() => downloadReportUpdateDate(report.Request_No, report.Download_Link)}
                            className="download-link"
                          >
                            Download
                          </a>
                        ) : (
                          "Link Expired"
                        )
                      ) : (
                        "Processing Request"
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
};

export default Form;
