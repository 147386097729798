import React from "react";
import { logout } from "./Utility";
import Form from "./Report_list_forms"; // Import our new modular dynamic form
// import config from "./DynamicFormConfig"; // Import the field configuration
import customFetch from "./apicall/api";

class Report_list extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reportList_data: [], // Dynamic report list data
      isSpin: false, // Loading spinner
      isErrOpen: false, // Error modal visibility
      api_error_message: "", // Error message for modal
    };
  }

  componentDidMount() {
    const { api_token, subcategories_id } =
      (this.props.location && this.props.location.state) || {};

    this.setState({ isSpin: true });
    const formdata = new FormData();
    formdata.append("report_subcategory_id", subcategories_id);

    customFetch(process.env.REACT_APP_URL + "/get/report/list", {
      method: "POST",
      headers: { "api-token": api_token },
      body: formdata,
    })
      .then((res) => res.json())
      .then((data) => {
        if ([3, 4, 5].includes(data.api_code)) {
          localStorage.clear();
          this.props.history.push("/");
        } else {
          this.setState({
            reportList_data: data,
            isSpin: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({ isSpin: false });
      });
  }

  render() {
    const { api_token, subcategories_name } =
      (this.props.location && this.props.location.state) || {};

    const { reportList_data, isSpin, isErrOpen, api_error_message } = this.state;

    return (
      <>
        {/* Spinner */}
        {isSpin && (
          <div className="preloader">
            <div className="loader"></div>
          </div>
        )}

        {/* Error Modal */}
        <div
          className={
            isErrOpen
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={() => this.setState({ isErrOpen: false })}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p>{api_error_message}</p>
            </div>
          </div>
        </div>

        {/* Header Section */}
        <div className="container no-container">
          <div className="top_nav_report">
            <a>
              <div
                className="icon_bg-report"
                onClick={() => this.props.history.goBack()}
              >
                <img
                  src={require("../assets/images/back-white.png")}
                  className="icon_img"
                />
              </div>
            </a>
            <span className="head-title">{subcategories_name}</span>
            <a>
              <div className="log-out-reporthead">
                <img
                  src={require("../assets/images/power-back.png")}
                  onClick={() => logout()}
                  alt=""
                />
              </div>
            </a>
          </div>

          {/* Dynamic Form */}
          <div>
            {reportList_data && reportList_data.length > 0 ? (
              <Form
                // config={config} // Pass the field configuration
                apiToken={api_token} // Pass the API token for fetching
                reportListData={reportList_data} // Pass the report list data
              />
            ) : (
              <div className="text-center">No Data Found</div>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default Report_list;
