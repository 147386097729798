// SubmitButton.js
import React from 'react';
import PropTypes from 'prop-types';

const SubmitButton = ({ onSave, text }) => {
  return (
    <div>
      <button 
        style={{
              backgroundColor: '#007bff',
              color: '#fff',
              border: '1px solid #007bff',
              padding: '5px 15px',
              fontSize: '16px',
              borderRadius: '5px',
              cursor: 'pointer',
              textAlign: 'center',
              display: 'inline-block',
              transition: 'background-color 0.3s',
            }}
            onMouseOver={(e) => (e.target.style.backgroundColor = '#0056b3')}
            onMouseOut={(e) => (e.target.style.backgroundColor = '#007bff')}
        onClick={onSave}
      >
        {text}
      </button>
    </div>
  );
};

SubmitButton.propTypes = {
  onSave: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};

export default SubmitButton;